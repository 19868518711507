<template>
  <Heading noDecoration text-align="center">Accommodations</Heading>
  <div
    class="accommodation"
    v-for="(accommodation, index) in accommodations"
    :key="index"
  >
    <div class="banner">
      <div class="banner-title">Do you need hotel room for conference day?</div>
      <div class="banner-description">
        Use the following code to book a hotel room
      </div>

      <div class="actions">
        <Tooltip
          :text="copied ? 'Code copied to clipboard' : 'Click to copy'"
          class="mt-5"
          style="display: block"
          v-if="accommodation.booking_code"
        >
          <Button @click="copyCode(accommodation.booking_code)">
            {{ accommodation.booking_code }}
          </Button>
        </Tooltip>
        <Button class="mt-5 ml-3" v-if="accommodation.booking_url">
          <a
            class="booking-link"
            :href="accommodation.booking_url"
            target="_blank"
          >
            Booking link
          </a>
        </Button>
      </div>
    </div>
    <div class="content" v-html="accommodation.description"></div>
  </div>
</template>

<script>
import config from "../../../public/config.json";
import Heading from "@/components/common/Heading";
import Tooltip from "@/components/common/Tooltip";
import Button from "@/components/common/Button";

export default {
  name: "AccommodationInfo",
  props: {
    accommodations: {
      type: Array,
      default: () => [],
    },
  },
  components: { Heading, Tooltip, Button },
  data: () => ({
    config,
    copied: false,
  }),
  computed: {},
  methods: {
    async copyCode(code) {
      console.log(code);
      try {
        await navigator.clipboard.writeText(code);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.actions {
  display: flex;
  align-items: center;

  .booking-link {
    text-decoration: none;
  }
}

.accommodation {
  display: flex;
  margin-top: 47px;
  column-gap: 32px;
  flex-wrap: wrap;

  &:not(:first-of-type) {
    margin-top: 24px;
  }

  .banner {
    padding: 16px;
    margin-bottom: 17px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    max-width: 619px;
    order: 1;

    &-title {
      font-family: $Oswald;
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      line-height: 36px;
      padding-bottom: 24px;
      letter-spacing: 0.05em;
    }

    &-description {
      margin-top: 24px;
    }
  }

  .deadline {
    color: $error-red;
  }

  .content {
    font-family: $Lato;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: justify;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-bottom: 64px;
  }

  @media (min-width: $media-sm) {
    flex-wrap: nowrap;
    .banner {
      order: 2;
      margin-bottom: 0;
      min-width: 374px;
      height: fit-content;
    }
  }
  @media (min-width: $media-lg) {
    margin-top: 87px;

    .banner {
      padding: 24px;
      min-width: 466px;
    }
  }
}
</style>
