<template>
  <div class="uploader">
    <div class="uploader__header">
      <span class="uploader__title">Booth Resources</span>
      <button type="button" class="add-material" @click="handleAddingImage">
        <div class="plus"></div>
      </button>
    </div>
    <div
      class="uploader__item"
      v-for="(file, index) in files"
      :key="index"
      @click="$refs[`${index}-file`].click()"
    >
      <div class="uploader__item__card">
        <img
          v-if="!file.loading"
          :src="
            file.name
              ? require('@/assets/img/icons/exhibit-uploaded.png')
              : require('@/assets/img/icons/exhibit-uploader.png')
          "
          alt="Sponsor logo"
        />
        <p v-if="!file.loading" :class="{ 'has-file': file.name }">
          {{ file.name || "Upload File" }}
        </p>
        <Preloader :loading="file.loading" />
      </div>
      <input
        :key="index"
        type="file"
        :ref="`${index}-file`"
        @change="upload($event, index)"
        hidden
      />

      <button
        class="remove-btn"
        @click.stop="removeResource(index)"
        v-if="files.length > 1 && file.name && !file.loading"
      >
        <i class="icon-remove"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Preloader from "@/components/common/Preloader";
import axios from "axios";
export default {
  components: { Preloader },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      files: [
        {
          value: "",
          name: "",
          url: "",
          loading: false,
        },
        // {
        //   value: "",
        //   name: "",
        //   url: "",
        //   add: true,
        //   loading: false,
        // },
      ],
    };
  },
  methods: {
    ...mapActions(["POST"]),
    upload(e, index) {
      console.log(index);
      const [file] = e.target.files;
      console.log(file);
      this.files[index].name = file.name;
      this.files[index].value = file.file;
      this.files[index].loading = true;

      this.POST({
        route: "/file/",
        data: {
          file_name: file.name,
        },
      }).then((res) => {
        const keyFields = [
          "AWSAccessKeyId",
          "key",
          "policy",
          "signature",
          "x-amz-security-token",
        ];
        const { url, fields } = res.data;
        const formData = new FormData();
        for (const key of keyFields) {
          formData.set(key, fields[key]);
        }
        formData.set("file", e.target.files[0]);
        axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.files[index].loading = false;
            this.files[index].url = `${url}${fields.key}`;
            this.$emit(
              "uploaded",
              this.files.map((file) => file.url)
            );
          });
      });
    },
    handleAddingImage() {
      this.files.push({
        value: "",
        name: "",
        url: "",
        loading: false,
      });
    },
    removeResource(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.uploader {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 12px;

  &__title {
    font-size: 18px;
    display: block;
    color: $white;
    width: 100%;
    flex: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__item {
    display: flex;
    // flex-basis: calc(33.3% - 12px);
    // max-width: calc(33.3% - 12px);
    // justify-content: center;
    // flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;

    &__card {
      background-color: transparent;
      width: 100%;
      display: flex;
      // flex-direction: column;
      // justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;

      p {
        text-overflow: ellipsis;
        font-family: $Lato;
        font-size: 13px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 8px;
        letter-spacing: 1.5px;
        color: $white;
        border: 2px solid rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        padding: 6px 80px 6px 12px;
        margin-left: 8px;

        &.has-file {
          width: 100%;
        }
      }

      img {
        max-width: 48px;
        max-height: 48px;
      }
    }
  }
}
.add-material {
  background-color: transparent;
  color: #fff;
  border-radius: 8px;
  padding: 4px 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;

  .plus {
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
  }

  .plus:before,
  .plus:after {
    content: "";
    position: absolute;
    background: #fff;
  }

  .plus:before {
    left: 50%;
    top: 4px;
    bottom: 4px;
    width: 2px;
    transform: translateX(-50%);
  }

  .plus:after {
    top: 50%;
    left: 4px;
    right: 4px;
    height: 2px;
    transform: translateY(-50%);
  }
}
.icon-remove {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid $red;
  border-radius: 22px;
}

.icon-remove::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: $red;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}

.remove-btn {
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 20px;
  background-color: rgb(15, 15, 15);
  padding: 0;
  border: none;
  cursor: pointer;
}
</style>
