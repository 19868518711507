<template>
  <div class="toggler">
    <input type="checkbox" :checked="open" :id="`toggler-${form}`" name="rd" />
    <label class="toggler__label" :for="`toggler-${form}`">
      {{ title }}
    </label>
    <div class="toggler__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },

    form: {
      type: String,
    },

    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.toggler {
  width: 100%;
  color: white;
  overflow: hidden;

  &__label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: $lighter-gray;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background: darken($lighter-gray, 10%);
    }

    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }

  &__content {
    max-height: 0;
    padding: 0 1em;
    color: $lighter-gray;
    transition: all 0.35s;
    opacity: 0;
  }
}

input {
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked {
    + .toggler__label {
      background: darken($lighter-gray, 10%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .toggler__content {
      max-height: 100vh;
      opacity: 1;
      padding: 1em;
    }
  }
}
</style>
